<template>
  <div>
    <h2 class="pl-2">
      {{ !this.isAdd ? 'Edytuj' : 'Dodaj ' }} Grupę celów
    </h2>
    <v-form v-model="valid" ref="targetForm" lazy-validation class="pb-10">
      <v-card class="mt-10 pa-5">
        <v-row>
          <v-col
            cols="9">
            <h4
              class="mb-5">
              Główne ustawienia
            </h4>
          </v-col>
          <v-col
            v-if="!this.isAdd"
            class="text-right"
            cols="3">
            <v-btn
              color="red"
              class="mr-10 white--text"
              @click="deleteTarget()"
            >
              {{ $t('common.delete') }} grupę celów
              <v-icon class="ml-2">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-text-field label="Nagłówek"
                          v-model='target.name'
                          :rules="[...rules.maxLengthTitle, ...rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <DatePickerInput v-model="target.from" :labelText="'Data od'" :rules="rules.required"></DatePickerInput>
          </v-col>
          <v-col cols="3">
            <DatePickerInput v-model="target.to" :labelText="'Data do'" :rules="rules.required"></DatePickerInput>
          </v-col>
        </v-row>
        <v-row class="ml-1 mt-5">
          <v-col cols="7">
            <span class="pl-2 text--secondary" style="font-size: 0.9rem">Kolor nagłówka</span>
            <v-color-picker
              dot-size="25"
              mode="hexa"
              swatches-max-height="20"
              v-model="target.headerColor"
            ></v-color-picker>
          </v-col>
          <v-col cols="4" class="mt-2">
            <v-select
              ref="targetsSelect"
              :items="targetItems"
              label="Podpięta grupa celów"
              :placeholder="'Niepodpięty'"
              :item-text="'name'"
              :item-value="'id'"
              v-model='attachedGroupId'
              persistent-placeholder
            >
              <template v-slot:item="{ item }">
                {{ `${item.name} ${item.to ? '- ' + item.to : ''}` }}
              </template>
            </v-select>
            <v-col cols="11" class="mt-4 ml-n3">
              <v-checkbox
                v-model="target.showSpeedTarget"
                label="Pokazuj cel pośredni (jeśli istnieje)"
              ></v-checkbox>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-10 pa-5">
        <h4>Kolumna postępu</h4>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-text-field label="Tekst"
                          v-model='target.progressColumnText'
                          :rules="[...rules.maxLengthTitle, ...rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-1 mt-5">
          <v-col cols="7">
            <span class="pl-2 text--secondary" style="font-size: 0.9rem">Kolor kolumny postępu</span>
            <v-color-picker
              dot-size="25"
              mode="hexa"
              swatches-max-height="20"
              v-model="target.progressColumnColor"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="this.target.showSpeedTarget" class="mt-10 pa-5">
        <h4>Kolumna celu pośredniego</h4>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-text-field label="Tekst górny"
                          v-model='target.requiredPointsSpeedColumnText'
                          :rules="[...rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Tekst dolny"
                          v-model='target.requiredPointsSpeedColumnSecondText'
                          :rules="[...rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-1 mt-5">
          <v-col cols="6">
            <span class="pl-2 text--secondary" style="font-size: 0.9rem">Kolor kolumny celu pośredniego</span>
            <v-color-picker
              dot-size="25"
              mode="hexa"
              swatches-max-height="20"
              v-model="target.requiredPointsSpeedColumnColor"
            ></v-color-picker>
          </v-col>
          <v-col cols="4" class="mt-12 ml-n9">
            <span v-if="!isAttachedGroupHaveSpeedTarget" class="text--disabled">Podpięta grupa celów nie posiada celu pośredniego (szybkościowego)</span>
            <span v-else class="text--disabled">Data końca celu pośredniego z SNN: {{attachedGroupSpeedTargetDeadline}}</span>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="this.target.showSpeedTarget" class="mt-10 pa-5">
        <h4>Nagroda cel pośredni</h4>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-text-field label="Nagłówek nagrody pośredniej"
                          v-model='target.speedRewardHeader'
                          :rules="[...rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-10 pa-5">
        <h4>Kolumna celu</h4>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-text-field label="Tekst górny"
                          v-model='target.requiredPointsColumnText'
                          :rules="[...rules.maxLengthTitle, ...rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Tekst dolny"
                          v-model='target.requiredPointsColumnSecondText'
                          :rules="[...rules.maxLengthTitle, ...rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-1 mt-5">
          <v-col cols="7">
            <span class="pl-2 text--secondary" style="font-size: 0.9rem">Kolor kolumny celu</span>
            <v-color-picker
              dot-size="25"
              mode="hexa"
              swatches-max-height="20"
              v-model="target.requiredPointsColumnColor"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-10 pa-5">
        <h4>Nagroda za cel</h4>
        <v-row class="mt-1">
          <v-col cols="4">
            <v-text-field label="Nagłówek nagrody"
                          v-model='target.rewardHeader'
                          :rules="[...rules.maxLengthTitle, ...rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Nagłówek nagrody po upływie czasu ze zrealizowanym celem"
                          v-model='target.rewardHeaderSuccess'
                          :rules="[...rules.maxLengthTitle]">
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Nagłówek nagrody po upływie czasu z niezrealizowanym celem"
                          v-model='target.rewardHeaderFail'
                          :rules="[...rules.maxLengthTitle]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field label="Tekst nagrody"
                          v-model='target.rewardText'
                          :rules="[...rules.maxLengthTitle, ...rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Tekst realizacji"
                          v-model='target.rewardDeadlineText'
                          :rules="[...rules.maxLengthTitle, ...rules.required]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-1 mt-5">
          <v-col cols="6">
            <span class="custom-label">Zdjęcie nagrody</span>
            <div v-if="target.rewardFileName">
                      <span class="mr-5">
                        {{ target.rewardFileOriginalName }}
                      </span>
              <v-btn
                class="mr-5"
                right
                @click="removeFile('reward')">
                Usuń plik
              </v-btn>
              <v-btn class="ml-2"
                     @click="openFile(target.rewardFileName)">
                Otwórz w nowej karcie
              </v-btn>
            </div>
            <v-file-input v-else
                          counter
                          show-size
                          truncate-length="15"
                          label="Zdjęcie nagrody"
                          v-model='target.rewardFile'
                          accept=".png, .jpeg, .jpg"
                          :rules="[...rules.fileType, ...rules.fileSize, ...rules.required]">
            </v-file-input>
          </v-col>
          <v-col cols="6">
            <span class="custom-label">Zdjęcie czasu realizacji</span>
            <div v-if="target.rewardDeadlineFileName">
                      <span class="mr-5">
                        {{ target.rewardDeadlineFileOriginalName }}
                      </span>
              <v-btn
                class="mr-5"
                right
                @click="removeFile('rewardDeadline')">
                Usuń plik
              </v-btn>
              <v-btn class="ml-2"
                     @click="openFile(target.rewardDeadlineFileName)">
                Otwórz w nowej karcie
              </v-btn>
            </div>
            <v-file-input v-else
                          counter
                          show-size
                          truncate-length="15"
                          label="Zdjęcie czasu realizacji"
                          v-model='target.rewardDeadlineFile'
                          accept=".png, .jpeg, .jpg"
                          :rules="[...rules.fileType, ...rules.fileSize, ...rules.required]">
            </v-file-input>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-10 pa-5">
        <h4>Dodatkowe ustawienia</h4>
        <v-row class="mt-1">
          <v-col cols="6">
            <v-checkbox
              v-model="target.showShopsList"
              label="Pokazuj listę sklepów"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="ml-1 mt-5">
          <v-col cols="7">
            <span class="custom-label">Grafika po osiągnięciu celu</span>
            <div v-if="target.finalRewardFileName">
                      <span class="mr-5">
                        {{ target.finalRewardFileOriginalName }}
                      </span>
              <v-btn
                class="mr-5"
                right
                @click="removeFile('finalReward')">
                Usuń plik
              </v-btn>
              <v-btn class="ml-2"
                     @click="openFile(target.finalRewardFileName)">
                Otwórz w nowej karcie
              </v-btn>
            </div>
            <v-file-input v-else
                          counter
                          show-size
                          truncate-length="15"
                          label="Grafika wygranej"
                          v-model='target.finalRewardFile'
                          accept=".png, .jpeg, .jpg"
                          :rules="[...rules.fileType, ...rules.fileSize, ...rules.required]">
            </v-file-input>
          </v-col>
        </v-row>
      </v-card>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="!target.isArchival"
          :disabled="!isAdd && isSaveChangesBtnDisabled ? true : false"
          color="blue"
          class="mr-4 mt-5 mb-5 white--text mr-10"
          @click="openNotificationDialog">
          {{ !isAdd ? 'Zapisz zmiany' : 'Dodaj grupę celów' }}
          <v-icon class="ml-2">mdi-archive-arrow-up</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>

    <confirm-delete-dialog
      endPoint="/target/DeleteNewTargetsGroupConfiguration"
      ref="confirmDeleteDialog"
      @submit="$router.push({ name: 'Targets'})"
    ></confirm-delete-dialog>
    <target-notification-dialog
      v-model="pushNotification"
      ref="targetNotificationDialog"
      @submit="submit">
    </target-notification-dialog>
  </div>
</template>

<script>
import {
  displayInfo,
  displayErrors
} from '@/utils/helpers'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'
import DatePickerInput from '@/components/DatePickerInput.vue'
import TargetNotificationDialog from '@/views/Targets/TargetNotificationDialog.vue'

export default {
  props: ['type'],
  name: 'AddOrEditTargets',
  components: {
    DatePickerInput,
    ConfirmDeleteDialog,
    TargetNotificationDialog
  },
  data: () => ({
    rules: {
      required: [v => !!v || 'Pole wymagane'],
      maxLengthTitle: [v => v.length <= 256 || 'Maksymalna ilosć 256 znaków.'],
      maxLengthDescription: [v => v.length <= 4096 || 'Maksymalna ilosć 4096 znaków.'],
      fileType: [v => !v || !!(/(\.jpg|\.jpeg|\.png)$/i.exec(v.name)) || 'Błędny plik. Dozwolone rozszerzenie to .jpg, .jpeg, .png'],
      fileSize: [v => !v || v.size < 30000000 || 'Wielkość pliku musi być mniejsza od 30MB']
    },
    isAdd: false,
    isSaveChangesBtnDisabled: false,
    valid: false,
    menu: false,
    target: {
      attachedGroup: {
        id: null,
        name: null,
        to: null
      },
      id: null,
      groupsIds: [],
      name: '',
      headerColor: null,
      progressColumnText: '',
      progressColumnColor: null,
      requiredPointsColumnText: '',
      requiredPointsColumnSecondText: '',
      requiredPointsColumnColor: null,
      requiredPointsSpeedColumnText: '',
      requiredPointsSpeedColumnSecondText: '',
      requiredPointsSpeedColumnColor: null,
      rewardHeader: '',
      rewardHeaderFail: '',
      rewardHeaderSuccess: '',
      rewardText: '',
      rewardDeadlineText: '',
      speedRewardHeader: '',
      showSpeedTarget: false,
      showShopsList: false,
      description: '',
      rewardFile: null,
      rewardFileName: null,
      rewardFileOriginalName: null,
      rewardDeadlineFile: null,
      rewardDeadlineFileName: null,
      rewardDeadlineFileOriginalName: null,
      finalRewardFile: null,
      finalRewardFileName: null,
      finalRewardFileOriginalName: null,
      from: '',
      to: ''
    },
    attachedGroupId: null,
    targets: [],
    pushNotification: {
      isAdd: false,
      none: false,
      sendOn: '',
      title: '',
      description: '',
      file: null,
      minDate: null
    }
  }),
  async created () {
    const id = this.$route.params.id

    if (id) {
      this.target.id = id
      await this.getDataAsync()
    }
    await this.getTargetsGroupsAsync()

    this.isAdd = this.target.id === undefined ||
      this.target.id == null ||
      this.target.id === ''
  },
  computed: {
    targetItems () {
      const nullOption = { id: null, name: 'Niepodpięty' }
      return [nullOption, ...this.targets]
    },
    attachedGroupSpeedTargetDeadline () {
      const attachedTargetsGroup = this.targets.find((target) => target.id === this.attachedGroupId)
      return attachedTargetsGroup.speedTargetDeadline
    },
    isAttachedGroupHaveSpeedTarget () {
      const attachedTargetsGroup = this.targets.find((target) => target.id === this.attachedGroupId)
      return !!(attachedTargetsGroup && attachedTargetsGroup.speedTargetDeadline != null)
    }
  },
  methods: {
    async getDataAsync () {
      const result = await this.$http.get(`/Target/GetNewTargetsGroupConfiguration?Id=${this.target.id}`)
      this.target = result.data
      this.target.attachedGroup ? this.attachedGroupId = this.target.attachedGroup.id : this.attachedGroupId = null
    },
    async getTargetsGroupsAsync () {
      this.targets = (await this.$http.get('Target/GetNewTargetsGroups')).data
    },
    removeFile (file) {
      if (file === 'reward') {
        this.target.rewardFileName = null
        this.target.rewardFileOriginalName = null
      }
      if (file === 'rewardDeadline') {
        this.target.rewardDeadlineFileName = null
        this.target.rewardDeadlineFileOriginalName = null
      }
      if (file === 'finalReward') {
        this.target.finalRewardFileName = null
        this.target.finalRewardFileOriginalName = null
      }
    },
    openFile (fileName) {
      window.open(`${process.env.VUE_APP_BASE}uploads/${fileName}`, '_blank')
    },
    deleteTarget () {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.id = this.target.id
      this.$refs.confirmDeleteDialog.message = this.target.name
    },
    openNotificationDialog () {
      if (this.isArticleNotChanged()) {
        displayInfo('Brak zmian do zapisania', 'info')
        return
      }

      this.pushNotification.none = false
      this.$refs.targetForm.validate()
      const now = new Date().toISOString()
      this.pushNotification.minDate = this.target.from < now ? now : this.target.from
      this.pushNotification.isAdd = this.isAdd

      if (!this.$refs.targetForm.validate()) {
        displayErrors('Uzupełnij wymagane pola lub popraw błędne wartości.')
        return
      }
      this.$refs.targetNotificationDialog.dialog = true
    },
    isArticleNotChanged () {
      return this.articleOrginal === JSON.stringify(this.target)
    },
    async submit () {
      const formData = new FormData()

      formData.append('NewTargetsGroupConfiguration.Id', this.target.id)
      formData.append('NewTargetsGroupConfiguration.Name', this.target.name)
      if (this.attachedGroupId) {
        formData.append('NewTargetsGroupConfiguration.NewTargetsGroupId', this.attachedGroupId)
      }
      formData.append('NewTargetsGroupConfiguration.From', this.target.from)
      formData.append('NewTargetsGroupConfiguration.To', this.target.to)
      const headerColor = typeof this.target.headerColor === 'object'
        ? this.target.headerColor.hex
        : this.target.headerColor
      formData.append('NewTargetsGroupConfiguration.HeaderColor', headerColor)
      formData.append('NewTargetsGroupConfiguration.ProgressColumnText', this.target.progressColumnText)
      const progressColumnColor = typeof this.target.progressColumnColor === 'object'
        ? this.target.progressColumnColor.hex
        : this.target.progressColumnColor
      formData.append('NewTargetsGroupConfiguration.ProgressColumnColor', progressColumnColor)
      formData.append('NewTargetsGroupConfiguration.RequiredPointsColumnText', this.target.requiredPointsColumnText)
      formData.append('NewTargetsGroupConfiguration.RequiredPointsColumnSecondText', this.target.requiredPointsColumnSecondText)
      const requiredPointsColumnColor = typeof this.target.requiredPointsColumnColor === 'object'
        ? this.target.requiredPointsColumnColor.hex
        : this.target.requiredPointsColumnColor
      formData.append('NewTargetsGroupConfiguration.RequiredPointsColumnColor', requiredPointsColumnColor)
      formData.append('NewTargetsGroupConfiguration.RewardHeader', this.target.rewardHeader)
      formData.append('NewTargetsGroupConfiguration.RewardHeaderFail', this.target.rewardHeaderFail)
      formData.append('NewTargetsGroupConfiguration.RewardHeaderSuccess', this.target.rewardHeaderSuccess)
      formData.append('NewTargetsGroupConfiguration.RewardText', this.target.rewardText)
      formData.append('NewTargetsGroupConfiguration.RewardDeadlineText', this.target.rewardDeadlineText)
      formData.append('NewTargetsGroupConfiguration.ShowSpeedTarget', this.target.showSpeedTarget)
      formData.append('NewTargetsGroupConfiguration.ShowShopsList', this.target.showShopsList)
      formData.append('NewTargetsGroupConfiguration.RewardFile', this.target.rewardFile)
      formData.append('NewTargetsGroupConfiguration.RewardFileName', this.target.rewardFileName ?? '')
      formData.append('NewTargetsGroupConfiguration.RewardDeadlineFile', this.target.rewardDeadlineFile)
      formData.append('NewTargetsGroupConfiguration.RewardDeadlineFileName', this.target.rewardDeadlineFileName ?? '')
      formData.append('NewTargetsGroupConfiguration.FinalRewardFile', this.target.finalRewardFile)
      formData.append('NewTargetsGroupConfiguration.FinalRewardFileName', this.target.finalRewardFileName ?? '')
      if (this.target.showSpeedTarget) {
        formData.append('NewTargetsGroupConfiguration.RequiredPointsSpeedColumnText', this.target.requiredPointsSpeedColumnText)
        formData.append('NewTargetsGroupConfiguration.RequiredPointsSpeedColumnSecondText', this.target.requiredPointsSpeedColumnSecondText)
        const requiredPointsSpeedColumnColor = typeof this.target.requiredPointsSpeedColumnColor === 'object'
          ? this.target.requiredPointsSpeedColumnColor.hex
          : this.target.requiredPointsSpeedColumnColor
        formData.append('NewTargetsGroupConfiguration.RequiredPointsSpeedColumnColor', requiredPointsSpeedColumnColor)
        formData.append('NewTargetsGroupConfiguration.SpeedRewardHeader', this.target.speedRewardHeader)
      }
      if (this.pushNotification.none === false) {
        formData.append('NewTargetsGroupConfiguration.PushNotification.Title', this.pushNotification.title)
        formData.append('NewTargetsGroupConfiguration.PushNotification.Description', this.pushNotification.description)
        formData.append('NewTargetsGroupConfiguration.PushNotification.File', this.pushNotification.file)
        formData.append('NewTargetsGroupConfiguration.PushNotification.SendOn', new Date(this.pushNotification.sendOn).toISOString())
      }

      try {
        const result = await this.$http.post(
          this.isAdd
            ? '/Target/AddNewTargetsGroupConfiguration'
            : '/Target/UpdateNewTargetsGroupConfiguration', formData
        )

        if (result.status === 200) {
          this.$refs.targetNotificationDialog.dialog = false
          const info = `Poprawnie ${this.isAdd ? 'dodano' : 'zaktualizowano'} grupę celów: ${this.target.name}`
          displayInfo(info)

          await this.$router.push({ name: 'Targets' })
        } else {
          displayErrors('Wystapił błąd')
        }
      } catch (e) {
      } finally {
        this.submitLoading = false
      }

      if (this.$refs.targetNotificationDialog?.isLoading) {
        this.$refs.targetNotificationDialog.isLoading = false
      }
    }
  },
  watch: {
    target: {
      handler () {
        if (!this.isAdd) {
          if (!this.isArticleNotChanged()) {
            this.isSaveChangesBtnDisabled = false
          } else {
            this.isSaveChangesBtnDisabled = true
          }
        }
      },
      deep: true
    }
  }
}
</script>
